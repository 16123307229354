import { getUrlVars, shippingDateSelectionReplace } from "../Shared/SiteCommon";
//import { cookie } from "../Shared/Cookie";

namespace KirakiraSiteCommon {
    const siteId = 14;
    jQuery(function () {
        setupEstimateButton();

        if (location.href.indexOf('/contact') != -1) {
            location.href = 'https://www.kirakirapunpun-maru.com/page/10';
        }

        shippingDateSelectionReplace();
    });


    function setupEstimateButton() {
        //カートの最初のページのみ発動
        if (document.getElementById("shoppingcart_page_step0") != null) {
            const form = jQuery("#register");
            const formBtns = form.find(".form_btn");
            const lastElem = formBtns.last();
            lastElem.append(
                `<span class="global_btn registerinput_btn">
    <button type="button" id="estimate" class="registerinput btn_size_xxlarge btn_color_emphasis">
        <span class="button_head_space"></span>
        <span class="button_text">見積書発行</span>
        <span class="button_end_space"></span>
    </button>
</span>`);
            const estBtn = document.getElementById("estimate");
            estBtn.onclick = <any>((a, b) => {
                toEstimate();
            });
        }
    }

    function toEstimate() {
        const formInnnerHtml = jQuery("#register").html();
        const results = new Array();
        let idx = 0;
        jQuery(".cart_data_box")
            .each(function () {
                results.push(parseProduct(idx, this));
                idx++;
            });

        const query = results.join("&");
        const url = "https://www.workup-system.com/Estimate/Print/?siteId=14&" + query;
        window.open(url);
    }

    function parseProduct(idx, itemDiv) {
        const productName = jQuery(itemDiv).find(".goods_name").text();
        const unitPrice = jQuery(itemDiv).find(".cart_price").find(".price").text().replace("円", "").replace(",", "");
        const selectNum = jQuery(itemDiv).find(".cart_quantity").val();
        const qentity = "[" + idx + "].";
        return qentity + "productName=" + encodeURIComponent(productName) + "&" + qentity + "unitPrice=" + unitPrice + "&" + qentity + "selectNum=" + selectNum;
    }
} 